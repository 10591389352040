<template>
    <div>
        <Header></Header>
        <div class="main">
            <div class="info">
                <div class="title">{{ $t('Honors') }}</div>
                <div class="list">
                    <div class="li">
                        <div class="img">
                            <img src="@/assets/images/h1.jpg">
                        </div>
                        <div class="txt">
                            展览陈列工程设计与施工一体化...
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <img src="@/assets/images/h2.jpg">
                        </div>
                        <div class="txt">
                            展览工程企业一级资质证书
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <img src="@/assets/images/h3.jpg">
                        </div>
                        <div class="txt">
                            装修装饰工程专业承包贰级资质
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <img src="@/assets/images/h4.jpg">
                        </div>
                        <div class="txt">
                            ISO9001质量管理体系认证证书
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <img src="@/assets/images/h5.jpg">
                        </div>
                        <div class="txt">
                            ISO14001环境管理体系认证证书
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <img src="@/assets/images/h6.jpg">
                        </div>
                        <div class="txt">
                            ISO45001-职业健康安全管理体...
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <img src="@/assets/images/h7.jpg">
                        </div>
                        <div class="txt">
                            AAA企业信用等级证书
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <img src="@/assets/images/h8.jpg">
                        </div>
                        <div class="txt">
                            AAA级资信等级证书
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <img src="@/assets/images/h9.jpg">
                        </div>
                        <div class="txt">
                            AAA重合同守信用企业
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <img src="@/assets/images/h10.jpg">
                        </div>
                        <div class="txt">
                            商贸流通业典型统计调查企业
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <img src="@/assets/images/h11.jpg">
                        </div>
                        <div class="txt">
                            中国工业设计协会常务委员单位
                        </div>
                    </div>
                    <div class="li">
                        <div class="img">
                            <img src="@/assets/images/h12.jpg">
                        </div>
                        <div class="txt">
                            国家文化产业研究中心会展创意...
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
   name: "Profile",
   components: {
    Footer,
    Header
  },
   data () {
    return {
      
    }
  },
  mounted () {
  
  },
   methods: {
   
   }
}
</script>


<style lang="less" scoped>
@import '~@/assets/css/honers';
</style>

